import { Card, Table, Tag, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import Loading from "../../components/loading";
import moment from "moment";
import { useEffect, useState } from "react";
import CardViewModal from "./viewCardsModal";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import PageHeaders from "../../components/pageHeaders";

function CommissionDetails() {
  const location = useLocation();
  const data = location.state?.val;
  const [Notifications, contextHolder] = notification.useNotification();
  const [cardView, setCardView] = useState(false);
  const [summary, setSummary] = useState<any>(0);
  const [refeCardData, setRefeCardData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>([]);
  useEffect(() => {
    getReferenceCardData(data?.id);
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Card Number",
      dataIndex: "cardNo",
      key: "cardNo",
    },
    {
      title: "Transaction Count",
      dataIndex: "transactionCount",
      key: "transactionCount",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];
  const getReferenceCardData = async (id: number) => {
    setIsLoading(true);
    try {
      let api = API.CARD_REFERENCE_DATA + id;
      const response: any = await GET(api);
      if (response?.status) {
        const totalAmount = response?.data?.transactions?.reduce(
          (sum: any, item: any) => sum + Number(item?.totalAmount),
          0
        );
        setSummary(totalAmount);
        setRefeCardData(response?.data);
        setIsLoading(false);
      } else {
        Notifications["error"]({
          message: "No data found",
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Commission details"} backButton={true} load />
      <div className="screenBox">
        <Card loading={isLoading} title={<div>Commission Management</div>}>
          <Row>
            <Col md="3">
              <Card>
                <div className="report-text1">NAME</div>
                <div className="report-text2">
                  {refeCardData?.card?.name ?? "No reference"}
                </div>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <div className="report-text1">CARD NUMBER</div>
                <div className="report-text2">
                  {refeCardData?.card?.cardNo ?? "No reference"}
                </div>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="report-text1">TOTAL CARDS</div>
                  <div className="ViewBtn" onClick={() => setCardView(true)}>
                    View All
                  </div>
                </div>
                <div className="report-text2">
                  {refeCardData?.cardReference?.length ?? 0}
                </div>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <div className="report-text1">TOTAL AMOUNT</div>
                <div className="report-text2">
                  {Number(summary).toFixed(2) ?? 0}
                </div>
              </Card>
            </Col>
          </Row>
          <br />
          <div>
            <Table
              columns={columns}
              dataSource={refeCardData?.transactions}
              rowKey="cardNo"
              bordered
            />
          </div>
        </Card>
      </div>
      {cardView ? (
        <CardViewModal
          visible={cardView}
          data={refeCardData?.cardReference}
          onCancel={() => setCardView(false)}
        />
      ) : null}
    </div>
  );
}

export default CommissionDetails;
