import { Button, Modal, Form, Select, Card, notification } from "antd";
import React, { useState, useEffect } from "react";
import Loading from "../../components/loading";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";

function BulkCreateModal(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const staffMap = new Map(
    props.staff.map((staff: any) => [staff.id, staff.name])
  );
  const activityMap = new Map(
    props.activities.map((activity: any) => [activity.id, activity.name])
  );
  const activityReverseMap = new Map(
    props.activities.map((activity: any) => [activity.name, activity.id])
  );
  const getLatestJobs = (data: any) => {
    const latestJobs = data.reduce((acc: any, job: any) => {
      if (
        !acc[job.staffId] ||
        new Date(acc[job.staffId].createdAt) < new Date(job.createdAt)
      ) {
        acc[job.staffId] = job;
      }
      return acc;
    }, {});
    return Object.values(latestJobs);
  };

  useEffect(() => {
    if (props?.data) {
      const latestJobs = getLatestJobs(props.data);
      const initialData = latestJobs.map((item: any) => {
        return {
          name: staffMap.get(String(item.staffId)),
          activities: item.activities.filter(Boolean).map((actId: string) => {
            return activityMap.get(String(actId));
          }),
          staffId: item?.staffId,
        };
      });
      form.setFieldsValue({ staff: initialData });
    }
  }, [props.data, form, staffMap, activityMap]);

  const handleStaffChange = (value: any, option: any, fieldKey: any) => {
    const fields = form.getFieldValue("staff");
    fields[fieldKey] = {
      ...fields[fieldKey],
      name: option?.children,
      staffId: Number(value),
    };
    form.setFieldsValue({ staff: fields });
  };

  const handleFinish = async (values: any) => {
    setIsLoading(true);
    try {
      const mappedValues = values.staff.map((item: any) => ({
        ...item,
        activities: item.activities
          .filter(Boolean)
          .map((activityName: string) => activityReverseMap.get(activityName)),
      }));
      const obj = {
        jobs: mappedValues.map((job: any) => ({
          ...job,
          status: true,
          totalSlots: 0,
          totalAmount: 0,
          dueAmount: 0,
          settledAmount: 0,
          upiSettle: 0,
          usedSlots: 0,
          isClockIn: "start",
        })),
      };
      const api = API.JOB_BULK_CREATE;
      const response: any = await POST(api, obj);
      if (response?.status) {
        Notifications["success"]({
          message: "Successfully Created Jobs",
        });
        setIsLoading(false);
        setTimeout(() => {
          props?.onSuccess();
        }, 1300);
      } else {
        Notifications["error"]({
          message: response?.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      Notifications["error"]({
        message: "Oops some thing went wrong ...",
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <Modal
        open={props.visible}
        title={<div style={{ fontSize: 25 }}>Bulk Job Create</div>}
        onCancel={props.onCancel}
        footer={false}
        width={1200}
      >
        <hr />
        {props?.loading ? (
          <Loading />
        ) : (
          <Form form={form} onFinish={handleFinish} layout="vertical">
            <Card>
              <Row style={{ marginBottom: "10px", fontWeight: "bold" }}>
                <Col span={6} md={4}>
                  Staff Name
                </Col>
                <Col span={12} md={7}>
                  Activities
                </Col>
              </Row>
              <Form.List name="staff">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }: any) => (
                        <Row key={key} gutter={16}>
                          <Col span={6} md={4} className="mt-1">
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              fieldKey={[fieldKey, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing staff name",
                                },
                              ]}
                              noStyle
                            >
                              <Select
                                allowClear
                                size="middle"
                                style={{ width: "100%" }}
                                placeholder="Select Staff"
                                defaultValue={restField.activities}
                                onChange={(value, option) =>
                                  handleStaffChange(value, option, key)
                                }
                              >
                                {props?.staff.map((option: any) => (
                                  <Select.Option
                                    key={option?.id}
                                    value={option?.id}
                                  >
                                    {option?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12} md={7} className="mt-1">
                            <Form.Item
                              {...restField}
                              name={[name, "activities"]}
                              fieldKey={[fieldKey, "activities"]}
                              noStyle
                            >
                              <Select
                                allowClear
                                mode="multiple"
                                size="middle"
                                style={{ width: "100%" }}
                                placeholder="Select Activities"
                                defaultValue={restField.activities}
                              >
                                {props?.activities.map((option: any) => (
                                  <Select.Option
                                    key={option?.id}
                                    value={option?.name}
                                  >
                                    {option?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            span={2}
                            md={1}
                            style={{ padding: 0 }}
                            className="mt-1"
                          >
                            <Button danger onClick={() => remove(name)}>
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      )
                    )}
                    <br />
                    <Form.Item className="mb-0">
                      <Button type="dashed" onClick={() => add()} block>
                        + Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Card>
            <br />
            <Row>
              <Col span={12}>
                <Button size="large" danger block onClick={props.onCancel}>
                  Close
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {props?.data?.id ? "Edit" : "Create"}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default BulkCreateModal;
