import React, { useState } from "react";
import { Button, Form, Input, Select, Switch, notification, Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { POST, COMPRESS_IMAGE } from "../../utils/apiRequest";
import { useForm } from "antd/es/form/Form";
import ImagePicker from "../components/ImagePicker";

function DiscountFormModal(props: any) {
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      var id = props?.data?.id;
      let discount = {
        id: Number(props?.data?.id),
        coupenCode: val?.coupenCode,
        amount: Number(val?.amount),
        status: val?.status,
      };
      let url = id ? API.DISCOUNT_EDIT : API.DISCOUNT_ADD;
      const data: any = await POST(url, discount);
      if (data?.status) {
        notification.success({
          message: `Success`,
          description: `Discount Successfully ${id ? "Updated" : "Created"}`,
        });
        setIsLoading(false);
        props?.onSuccess();
      } else {
        notification.error({
          message: "Faild",
          description: data.message,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      Notifications["error"]({
        message: "Faild",
        description: "Oops ! Something went wrong",
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={props.visible}
      title={
        <div
          style={{ fontSize: 25 }}
        >{`${props?.data?.id ? "Edit" : "Create"} Discount`}</div>
      }
      onCancel={props.onCancel}
      footer={false}
      width={800}
    >
      {contextHolder}
      <hr />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          coupenCode: props?.data?.coupenCode,
          amount: props?.data?.amount,
          status: props?.data?.status,
        }}
      >
        <Row>
          <Col md={6}>
            <label className="formLabel">Coupen code</label>
            <Form.Item name={"coupenCode"}>
              <Input size="large" />
            </Form.Item>
            <label className="formLabel">Status</label>
            <Form.Item
              name="status"
              valuePropName={props?.data.status ? "checked" : "null"}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col md={6}>
            <label className="formLabel">Amount</label>
            <Form.Item
              name={"amount"}
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
              ]}
            >
              <Input type="number" size="large" />
            </Form.Item>
          </Col>
          <Row>
            <Col sm={6}>
              <Button size="large" danger block onClick={props.onCancel}>
                Close
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                size="large"
                type="primary"
                block
                htmlType="submit"
                loading={isLoading}
              >
                {props?.data?.id ? "Edit" : "Create"}
              </Button>
            </Col>
          </Row>
        </Row>
      </Form>
    </Modal>
  );
}

export default DiscountFormModal;
